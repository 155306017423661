/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*Photo by Pietro De Grandi on Unsplash*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em",
    pre: "pre",
    code: "code",
    h2: "h2",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", React.createElement(_components.p, null, "Gatsby has a great guide for upgrading from v1 to v2.\nThis post describes how I upgraded this site from v1 to v2. If it reads almost identically to their ", React.createElement(_components.a, {
    href: "https://next.gatsbyjs.org/docs/migrating-from-v1-to-v2/"
  }, "official guide"), ", it’s because their guide is awesome. ", React.createElement(_components.em, null, "if it doesn’t, their guide is still awesome"), "."), "\n", React.createElement(_components.p, null, "Before I got started, I created a new branch in git where all changed would live.\nThis branch will get merged into the master branch after this post 💪."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "git checkout -b gatsby-v2\n")), "\n", React.createElement(_components.h2, {
    id: "remove-gatsby-link-from-your-packagejson"
  }, "Remove ", React.createElement(_components.code, null, "gatsby-link"), " from your ", React.createElement(_components.code, null, "package.json")), "\n", React.createElement(_components.p, null, "You no longer need the ", React.createElement(_components.code, null, "gatsby-link"), " package. Everything that used to be there now lives in the main ", React.createElement(_components.code, null, "gatsby"), " dependency.\nWe will adjust our imports later to reflect this change."), "\n", React.createElement(_components.h2, {
    id: "update-your-dependencies"
  }, "Update your dependencies"), "\n", React.createElement(_components.p, null, "Change the versions off all dependencies that contain ", React.createElement(_components.code, null, "gatsby"), " in their name to ", React.createElement(_components.code, null, "next"), ". You don’t have to install them via the terminal just yet, just update the versions in ", React.createElement(_components.code, null, "package.json"), "."), "\n", React.createElement(_components.p, null, "For this site that meant my ", React.createElement(_components.code, null, "package.json"), " now looked like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "\"dependencies\": {\n    \"gatsby\": \"next\",\n    \"gatsby-image\": \"next\",\n    \"gatsby-plugin-catch-links\": \"next\",\n    \"gatsby-plugin-google-analytics\": \"next\",\n    \"gatsby-plugin-manifest\": \"next\",\n    \"gatsby-plugin-netlify\": \"next\",\n    \"gatsby-plugin-offline\": \"next\",\n    \"gatsby-plugin-react-helmet\": \"next\",\n    \"gatsby-plugin-sharp\": \"next\",\n    \"gatsby-plugin-sitemap\": \"next\",\n    \"gatsby-plugin-styled-components\": \"next\",\n    \"gatsby-plugin-typography\": \"next\",\n    \"gatsby-remark-copy-linked-files\": \"next\",\n    \"gatsby-remark-images\": \"next\",\n    \"gatsby-remark-prismjs\": \"next\",\n    \"gatsby-source-filesystem\": \"next\",\n    \"gatsby-transformer-remark\": \"next\",\n    \"gatsby-transformer-sharp\": \"next\",\n  }\n")), "\n", React.createElement(_components.p, null, "at this point I also deleted my ", React.createElement(_components.code, null, "node-modules"), " folder and my lockfile ", React.createElement(_components.code, null, "package-lock.json"), " (or ", React.createElement(_components.code, null, "yarn.lock"), ")."), "\n", React.createElement(_components.h2, {
    id: "install-react"
  }, "Install React"), "\n", React.createElement(_components.p, null, "In v1 ", React.createElement(_components.code, null, "react"), " and ", React.createElement(_components.code, null, "react-dom"), " were included in the main ", React.createElement(_components.code, null, "gatsby"), " dependency. This is no longer the case."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm i react react-dom\n")), "\n", React.createElement(_components.h2, {
    id: "install-peer-dependencies-for-your-gatsby-plugins"
  }, "Install peer dependencies for your Gatsby plugins"), "\n", React.createElement(_components.p, null, "Many packages depend on other packages to function correctly.\nMake sure those dependencies are there.\nIf you don’t know which packages you should install, go to the ", React.createElement(_components.a, {
    href: "https://next.gatsbyjs.org/plugins/"
  }, "Gatsby plugin browser"), " and look at the line of code that tells you how to install the plugin. They have a line for each package in their plugin brower that looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install --save gatsby-plugin-something optional-peer-dependency another-optional-dependency\n")), "\n", React.createElement(_components.p, null, "Also if you forget to install the peer dependencies and run ", React.createElement(_components.code, null, "npm install"), " it will warn you in the console about unmet peer dependencies."), "\n", React.createElement(_components.p, null, "for me that meant:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm i react helmet styled-components babel-plugin-styled-components react-typography typography\n")), "\n", React.createElement(_components.h2, {
    id: "update-and-move-the-layout-file"
  }, "Update (and move) the layout file."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "children()"), " now is no longer a function that has to be called, so remove those parentheses! Use ", React.createElement(_components.code, null, "children"), " instead.\nThe official docs also recommend moving the file into the components directory. My components directory already had a folder for every component, so I moved the existing file at ", React.createElement(_components.code, null, "src/layouts/index.js"), " into it’s own folder too.\nIt now lives at ", React.createElement(_components.code, null, "src/components/Layout/Layout.js"), " along with the accompanying ", React.createElement(_components.code, null, ".css"), " file it had.\nDon’t forget to update all places you import these files!\nThe imports you use in the ", React.createElement(_components.code, null, "Layout.js"), " file also have a high chace of needing edits.\nI could now delete the old layouts folder (since it is empty)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "- import theme from \"../utils/theme\";\n+ import theme from \"../../utils/theme\";\n\n- import Header from \"../components/Header/Header\";\n+ import Header from \"../Header/Header\";\n- import Footer from \"../components/Footer/Footer\";\n+ import Footer from \"../Footer/Footer\";\n")), "\n", React.createElement(_components.h2, {
    id: "wrap-all-pages-in-that-component"
  }, "Wrap all pages in that component"), "\n", React.createElement(_components.p, null, "Ensure all pages get wrapped by that component.\nthe ", React.createElement(_components.code, null, "404.js"), " file in the ", React.createElement(_components.code, null, "src/pages"), " directory now looks like this"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "import React from 'react';\n+ import Layout from '../components/Layout/Layout'\n\nconst NotFoundPage = () => (\n-  <div>\n+  <Layout>\n    <h1>NOT FOUND</h1>\n    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>\n+  </Layout>\n-  </div>\n);\n\nexport default NotFoundPage;\n")), "\n", React.createElement(_components.p, null, "Don’t forget to also do this in your ", React.createElement(_components.code, null, "src/templates/"), " files if you use ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.org/tutorial/part-seven/#creating-pages"
  }, "templates"), "!"), "\n", React.createElement(_components.h2, {
    id: "replace-gatsby-link-imports"
  }, "Replace gatsby-link imports"), "\n", React.createElement(_components.p, null, "Remember when we deleted ", React.createElement(_components.code, null, "gatsby-link"), " from our ", React.createElement(_components.code, null, "package.json"), "?\nLets update all instances where we used ", React.createElement(_components.code, null, "gatsby-link"), " to use ", React.createElement(_components.code, null, "gatsby"), " instead."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "- import Link from 'gatsby-link'\n+ import { Link } from 'gatsby'\n")), "\n", React.createElement(_components.h2, {
    id: "add-graphql-imports"
  }, "Add graphql imports"), "\n", React.createElement(_components.p, null, "In v1 you could export queries that used ", React.createElement(_components.code, null, "graphql"), " without first importing it 😕. Gatsby would know what you meant, and everything would work. The Gatsby docs explain ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.org/tutorial/part-four/#wait--where-did-the-graphql-tag-come-from"
  }, "how it works"), ". ", React.createElement(_components.em, null, "or worked, since ", React.createElement(_components.code, null, "graphql"), " is no longer there in v2 without first importing it.")), "\n", React.createElement(_components.p, null, "You should no longer export a graphql query without first importing ", React.createElement(_components.code, null, "graphql"), " in v2."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "+ import { graphql } from \"gatsby\";\n")), "\n", React.createElement(_components.p, null, "Adding this allowed me to remove the"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "- // eslint-disable-next-line\n")), "\n", React.createElement(_components.p, null, "I talked about in my ", React.createElement(_components.a, {
    href: "/blog/automagically-lint"
  }, "automagically-lint"), " blogpost"), "\n", React.createElement(_components.h2, {
    id: "rename-boundactioncreators-to-actions"
  }, "Rename boundActionCreators to actions"), "\n", React.createElement(_components.p, null, "Rename ", React.createElement(_components.code, null, "boundActionCreators"), " to ", React.createElement(_components.code, null, "actions"), ", you will probably find this in your ", React.createElement(_components.code, null, "gatsby-node.js"), " file."), "\n", React.createElement(_components.h2, {
    id: "rename-image-queries"
  }, "Rename image queries"), "\n", React.createElement(_components.p, null, "The image queries ", React.createElement(_components.code, null, "sizes"), " and ", React.createElement(_components.code, null, "resolutions"), " were renamed to ", React.createElement(_components.code, null, "fluid"), " and ", React.createElement(_components.code, null, "fixed"), " respectively."), "\n", React.createElement(_components.p, null, "That means I updated a few of my ", React.createElement(_components.code, null, "graphql"), " queries:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "frontmatter {\n    cover {\n      childImageSharp {\n-     sizes {\n+     fluid {\n-        ...GatsbyImageSharpSizes_withWebp\n+        ...GatsbyImageSharpFluid_withWebp\n        }\n      }\n    }\n  }\n")), "\n", React.createElement(_components.p, null, "But don’t remove ", React.createElement(_components.code, null, "sizes"), " at the lowest level of your query!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json",
    hl: "11"
  }, "frontmatter {\n    cover {\n        childImageSharp {\n            fluid {\n                base64\n                aspectRatio\n                src\n                srcSet\n                srcWebp\n                srcSetWebp\n                sizes\n            }\n        }\n    }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "use-the-same-method-importingexporting"
  }, "Use the same method importing/exporting"), "\n", React.createElement(_components.p, null, "A single file should use only ES6 modules (", React.createElement(_components.code, null, "import"), " syntax) or only CommonJS (", React.createElement(_components.code, null, "require"), " syntax), not a mix of both."), "\n", React.createElement(_components.p, null, "The only place I had to change any code was in my ", React.createElement(_components.code, null, "Layout.js"), " file, where I required some fonts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "- require('typeface-roboto');\n+ import 'typeface-roboto';\n- require('typeface-roboto-slab');\n+ import 'typeface-roboto-slab';\n")), "\n", React.createElement(_components.h2, {
    id: "make-remaining-necessary-changes"
  }, "Make remaining necessary changes"), "\n", React.createElement(_components.p, null, "I hit a snag because this site uses ", React.createElement(_components.code, null, "typography"), ".\n", React.createElement(_components.code, null, "scale"), " and ", React.createElement(_components.code, null, "rhythm"), " weren’t explicity exported."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff"
  }, "const typography = new Typography(oceanBeachTheme);\n+ const { rhythm, scale } = typography;\n\n- export default typography\n+ export { rhythm, scale, typography as default };\n")), "\n", React.createElement(_components.p, null, "edit: Made a ", React.createElement(_components.a, {
    href: "https://github.com/gatsbyjs/gatsby/pull/6151"
  }, "PR"), " that adds this info to the ", React.createElement(_components.a, {
    href: "https://next.gatsbyjs.org/docs/migrating-from-v1-to-v2/"
  }, "official migration guide"), "\nand it got accepted 🎉🎉🎉!"), "\n", React.createElement(_components.p, null, "This post didn’t mention lots of other changes that this site didn’t need, but are covered in the official documentation, please ", React.createElement(_components.a, {
    href: "https://next.gatsbyjs.org/docs/migrating-from-v1-to-v2/"
  }, "go check it out"), "!"), "\n", React.createElement(_components.h2, {
    id: "turn-it-on"
  }, "Turn it on"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "delete the ", React.createElement(_components.code, null, ".cache"), " and ", React.createElement(_components.code, null, "public"), " directories"), "\n", React.createElement(_components.li, null, "install all your dependencies: ", React.createElement(_components.code, null, "npm i"), " or ", React.createElement(_components.code, null, "yarn"), " depending on your preference."), "\n", React.createElement(_components.li, null, "run ", React.createElement(_components.code, null, "gatsby develop")), "\n", React.createElement(_components.li, null, "cross your fingers"), "\n", React.createElement(_components.li, null, "celebrate 🎉🎉🎉"), "\n"), "\n", React.createElement(_components.p, null, "Congratulations, your blazingly fast website is now even faster than before! 🔥🔥🔥\nYou can also use a bunch of ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.org/blog/2018-06-16-announcing-gatsby-v2-beta-launch/"
  }, "great new features"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
